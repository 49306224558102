<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ESFP</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Anda yang memiliki kepribadian ESFP biasa disebut sebagai The Performers atau seorang pemain. Hanya ada 4-9% kepribadian ESFP di dunia. Menurut dimensinya ESFP adalah Extroversion yaitu mereka yang bisa membangun jaringan dengan luas. Sensing, yakni mereka yang tidak suka akan hal abstrak dan lebih suka hal konkret. Kemudian Feeling, yaitu melibatkan masalah pribadi dalam mengambil keputusan dan terakhir yakni Perception. Dimana mereka lebih menyukai kesepakatan yang diambil dibandingkan menjadi seseorang yang merencanakan segala hal lebih dulu. 
          </p>
          <p>
            Ciri kepribadian ESFP adalah:
            <ul>
              <li> Hidup di masa sekarang dengan berpikir realistis serta praktis.</li>
              <li> Spontan dan jarang merencanakan segala sesuatunya.</li>
              <li> Tidak menyukai teori yang panjang dan tahu bagaimana menciptakan hal untuk menyenangkan orang lain.</li>
              <li> Seseorang dengan keterampilan yang cukup baik.</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajarlah menghadapi kritik dan jangan coba untuk lari, apalagi yang menyangkut keputusan dan hal di masa depan.
              </li>
              <li>
                Anda cenderung materealitstis jadi cobalah tidak mengukur segala hal dengan uang.
              </li>
              <li>
                Pekerjaan yang cocok yakni tenaga penjualan, fotografer, pemerhati anak, decorator, seniman atau aktor.
              </li>
            </ul>
          </p>
          <p>
            Asmara dari ESFP yang cocok adalah mereka yang ISTJ atau ISFJ. Untuk itu, mereka yang memiliki sifat sama-sama memiliki sensing yang baik cocok.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ESFP.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Sensing.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="sensing">Sensing</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Perceiving.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="perceiving">Perceiving</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ESFP",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ESFP',
        slug: 'esfp'
      }
    }
  }
};
</script>

<style scoped>

</style>
